import React from "react";

const DownloadCSV = ({ data, fileName, header, caption = "Download" }) => {
    const convertToCSV = (objArray) => {
        const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        let str = "";
        if (header) {
            let line = "";
            for (let index in header) {
                if (line !== "") line += ",";

                line += header[index];
            }
            str += line + "\r\n";
        }

        for (let i = 0; i < array.length; i++) {
            let line = "";
            for (let index in array[i]) {
                if (line !== "") line += ",";

                line += array[i][index];
            }
            str += line + "\r\n";
        }
        return str;
    };

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement("a");
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <input type="button" value={caption} onClick={downloadCSV} className="btn" />;
};

export default DownloadCSV;
